import React from 'react'
import './Review.scss'

export default function Review() {
    return (
        <div>
            <div className='mainbar'>

            </div>
            <div className='down'>
                <div className='downbar'>


                </div>
                <div className='proscroll'>

                </div>
            </div>
        </div>
    )
}
