import React from 'react'
import Suc from '../assets/sucsses.gif' 
import "./Sucsses.scss"

export default function Sucsses() {
  return (
    <div className='suc'>
      <img src={Suc} alt="" />
    </div>
  )
}
