import React from 'react'
import { CModal, CButton, CModalHeader, CModalBody, CModalTitle, CModalFooter, CImage } from '@coreui/react'
import { useState } from 'react'
import Add from '../../assets/ela.gif'
import { useNavigate } from 'react-router-dom'
import './Ad.scss'

export default function Ad() {
    const navigate = useNavigate()
    const [visible, setVisible] = useState(true)
  return (
    <div>
        <>
    <CModal
      alignment="center"
      scrollable
      visible={visible}
      onClose={() => setVisible(false)}
      aria-labelledby="VerticallyCenteredScrollableExample2"
    >
      <CModalHeader>
        {/* <CModalTitle id="VerticallyCenteredScrollableExample2">Modal title</CModalTitle> */}
      </CModalHeader>
        <img className='ad' src={Add} />
      {/* <CModalBody>
      </CModalBody> */}
      <CModalFooter>
        <CButton color="secondary" onClick={() => setVisible(false)}>
          Close
        </CButton>
        <CButton href='https://mystikfalls.com/category/9' color="warning">Visit Collection</CButton>
      </CModalFooter>
    </CModal>
  </>
    </div>
  )
}
