import React from 'react'
import { CCard, CCardTitle, CCardText, CCardBody, CCardImage } from '@coreui/react'
import B1 from '../../assets/B1.jpg'
import B2 from '../../assets/B2.jpg'
import B3 from '../../assets/B3.jpg'
import B4 from '../../assets/B4.jpg'
import B5 from '../../assets/B5.jpg'

export default function Blog() {
  return (
    <div>
      <CCard className="mb-3">
  <CCardBody>
    <CCardTitle>Types of Snake plants for your Home</CCardTitle>
    <CCardText><p>Snake plant is commonly known as “Mother-in law’s tongue”, “Saint George Sword”. It belongs to sansevieria genus.</p>
    <p>It is popular houseplant for its minimal upkeep requirements. These plants has long sword shapes unique pattern of leaves.</p>
    <p>Scientific Name: <em>Dracaena trifasciata</em>, scientific name derived from its origin Italian prince. Botanist Raimondo di Sangro was promoted its cultivation during eighteen centaury. </p>
    <p>Family: Sansevieria, this botanical family has diverse regions across Africa, Madagascar, and Southern Asia.</p>
    <p><b>Care tips:</b></p>
    <p><b>Water needs: </b>This species is drought-tolerant, water only every 1-2 weeks, once per month during winter. In spring to autumn allow the soil to dry between watering.</p>
    <p><b>Light needs: </b>
    <p>⦁	Full shade for full sunlight  </p>
    <p>⦁	Direct sunlight only for 2-6 hours o few hours of early morning direct sunlight. Too much direct sunlight damages its leaves. </p>
    <p>⦁	It tolerates some shade but lack of light can affect its growth and also dilute its original color. </p></p>
    <p><b>Temperature and humidity: </b>It tolerates low humidity and cool temperature around 50 degrees. Normal household temperature is ideal for this plant.</p>
    <p><b>Soil texture: </b>loose sandier soils, well drained, potting mix is ideal. Compacted potting mixes have an issue for draining</p>
    <p><b>Soil pH: </b>The soil pH is required slightly acidic to slightly alkaline.</p>
    <p><b>Fertilizer: </b>They don’t require too much fertilizer, prefer diluted succulent fertilizer. During winter no fertilizer is necessary.</p>
    <p><b>Growth rate: </b>Growth rate of these plants are very slow. </p>
    <p><b>Maintenance: </b>The hidden benefit of a snake plant is its low-maintenance nature. Most plant can survive for long time without water. Also the plant is highly pest resistant.</p>
    <b><u>Types of snake plants</u></b>
    <p>Snake plant has an extensive range of verities. Each plant has its own set of distinctive of characteristics and features. Let explore some popular types of snake plants.</p>
    <b>African Spear Dracaena</b>
    <p><b>Botanical Name: </b><em><u>Sansevieria cylindrical</u></em></p>
    <p><b>Family: </b><em><u>Asparagaceae</u></em></p>
    <p><b>Genus: </b><em><u>Dracaena</u></em></p>
    <p><b>Kingdom: </b><em><u>Plantae</u></em></p>
    <p>African Spear plant is commonly known as cylindrical plant for its distinctive cylindrical leaves. This variety features long, elongate, striped, smooth, greenish- gray leaves. Young leaves are thinner and easy to bend. These plants grow best in hot and dry climates.  </p>
    <p>Nurture this plant in well drained soil and wet the soil occasionally, like once in a weak in summer and once in a month in winter season.</p>
    <p>Plant type: Herbaceous Perennial, houseplant succulent.</p>
    <CCardImage orientation="bottom" src={B1} />
    <b>Sansevieria trifasciata Golden Hahnii</b>
    <p><b>Botanical Name: </b><em><u>Sansevieria trifasciata </u></em></p>
    <p><b>Family: </b><em><u>Asparagaceae</u></em></p>
    <p><b>Genus: </b><em><u>Sansevieria</u></em></p>
    <p>It’s a compact form of rosette-shaped leaves. It has dark, board; green leaves, and creamy white horizontal stripes, leaves are flat, smooth and taper towards end. 
The foliage grows closely together, resembling as birds’ nests.</p>
<p>It is a dwarf verity of snake plant. It’s a small is size, hence it an excellent choice for small space such as tabletop, centre place of plant arrangements.</p>
<CCardImage orientation="bottom" src={B2} />
<b>Dracaena trifasciata bantel’s sensastion</b>
<p><b>Pant type: </b>hardy perennial</p>
<p>It is commonly referred as white dracaena; this unique plant gets its name from St. Louise Missourian Gustav Bantal. Although this dracaena was developed in nursery but its parent plant is native to the tropical regions of western Africa.</p>
<p>It has rare, unusual, distinctive white vertical striping in the leaves.  Sweetly fragrant white flowers bloom during the long days of summer.</p>
<CCardImage orientation="bottom" src={B3} />
<b>Dracaena hyacinthoides</b>
<p><b>Botanical Name: </b><em><u>Dracaena hyacinthoides</u></em></p>
<p><b>Family: </b><em><u>Asparagaceae</u></em></p>
<p><b>Genus: </b><em><u>Dracaena</u></em></p>
<p><b>Plant type: </b><em><u>Hardy perennial</u></em></p>
<p>The native land of Dracaena hyacinthoides is in east, central and south Africa. Dracaena Hyacinthoides has olive-green flat, fibrous leaves that form a sword shape. They produce tall flower spikes on which greenish-white small, tubular flowers appear in clusters.</p>
<p>It can absorbs airborne toxins and convert it into oxygen.</p>
<CCardImage orientation="bottom" src={B4} />
<b>Black Dragon</b>
<p><b>Botanical Name: </b><em><u>Dracaena trifasciata ‘Black Dragon’</u></em></p>
<p><b>Family: </b><em><u>Asparagaceae</u></em></p>
<p><b>Genus: </b><em><u>Dracaena</u></em></p>
<p>Care tips: water requires once in a week and need bright indirect sunlight. It can tolerate very low light</p>
<p>Black dragon is also known as ‘future midnight’. This beautiful snake plant has dark black, glossy leaves. Its flat leaves have dark green horizontal bands. It is very rare verity with compact leaves. </p>
<CCardImage orientation="bottom" src={B5} />
<b>Benefits</b>
<u>Air purification</u>
<p>According to NASA research, snake plants can improve indoor air quality and remove toxins in small or medium-sized areas.
</p>
<p>⦁	Ability to remove toxins and pollutants from the air and also absorb cancer causing pollutants </p>
<p>⦁	Specifically pollutants like formaldehyde, benzene, xylene, toluene, formaldehyde </p>
<p>⦁	Effective in low-light environment,</p>
<p>⦁	Snakes plants help to filter indoor air; it converts carbon-dioxide (CO2) to Oxygen (O2) at night. So it is a ideal plant for bedroom decor, </p>
<u>Health benefits</u>
<p>⦁	Reducing headache, eye irritation, respiratory problem</p>
<p>⦁	Improve concentration & productivity</p>
<p>⦁	Absorb negative energy from surrounding. </p>
<p>⦁	Working with plants may reduce psychological & physiological stress.</p>
<p>It is suggested to place the plant near appliances that emit harmful radiation. </p>
    </CCardText>
    <CCardText><small className="text-body-secondary">Updated on 3 April 2024</small></CCardText>
  </CCardBody>
  
</CCard>
    </div>
  )
}
