import React, { useContext } from "react";
import { MdClose } from "react-icons/md";
import { BsCartX } from "react-icons/bs";
import { Context } from "../../utils/context";
import CartItem from "./CartItem/CartItem";
import { loadStripe } from "@stripe/stripe-js";
import { makePaymentRequest } from "../../utils/api";
import axios from "axios";
import { useNavigate } from "react-router-dom";


import "./Cart.scss";

const Cart = () => {
    const { cartItems, setShowCart, cartSubTotal } = useContext(Context);

    const stripePromise = loadStripe(
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    );

    const handlePayment = async () => {
        try {
            // const stripe = await stripePromise;
            // const res = await makePaymentRequest.post("/api/orders", {
            //     name: '',
            // });
            // // await stripe.redirectToCheckout({
            // //     sessionId: res.data.stripeSession.id,
                
            // // });
            await axios.post(
                `http://localhost:1337/api/orders`,
                {
                  data: {
                    name: '',
                    products: cartItems,
                    price: cartSubTotal
                  }
                }
              );
        } catch (err) {
            console.log(err);
            console.log("not done")
        }
    };
    const navigate = useNavigate();
    
    return (
        <div className="cart-panel">
            <div
                className="opac-layer"
                onClick={() => setShowCart(false)}
            ></div>
            <div className="cart-content">
                <div className="cart-header">
                    <span className="heading">Shopping Cart</span>
                    <span
                        className="close-btn"
                        onClick={() => setShowCart(false)}
                    >
                        <MdClose className="close-btn" />
                        <span className="text">close</span>
                    </span>
                </div>

                {!cartItems.length && (
                    <div className="empty-cart">
                        <BsCartX />
                        <span>No products in the cart.</span>
                        <button className="return-cta" onClick={() => {}}>
                            RETURN TO SHOP
                        </button>
                    </div>
                )}

                {!!cartItems.length && (
                    <>
                        <CartItem />
                        <div className="cart-footer">
                            <div className="subtotal">
                                <span className="text">Subtotal:</span>
                                <span className="text total">
                                    &#8377;{cartSubTotal}
                                </span>
                            </div>
                            <div className="button">
                                <button
                                    className="checkout-cta"
                                    onClick={()=>navigate('/payment') | setShowCart(false)}
                                >
                                    Checkout
                                </button>
                            </div>
                            {/* {console.log(cartItems)} */}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Cart;