import React from 'react'
import { CAccordion, CAccordionBody, CAccordionHeader, CAccordionItem } from '@coreui/react'

export default function Tc() {
  return (
    <div>
      <h2>Terms & Conditions For Return</h2>
      <CAccordion activeItemKey={2}>
  <CAccordionItem itemKey={1}>
    <CAccordionHeader>Return and Cancelling an Order:</CAccordionHeader>
    <CAccordionBody>
    If you change your mind after placing an order, you can cancel the order within 24 hours of the order confirmation.
    </CAccordionBody>
  </CAccordionItem>
  <CAccordionItem itemKey={2}>
    <CAccordionHeader>Return and Exchange policy:</CAccordionHeader>
    <CAccordionBody>
    We have <strong>24 hours replacement policy after the delivery of the product.</strong> We don't have a Return policy.No fee will be deducted for the exchange of the product.
    </CAccordionBody>
  </CAccordionItem>
  <CAccordionItem itemKey={3}>
    <CAccordionHeader>Exchanging an Item:</CAccordionHeader>
    <CAccordionBody>
    We are giving you live plants so sometimes you can find some minimal difference in your ordered product from our website image. It's very natural for living plants. This issue is not acceptable for exchanging an item.
    </CAccordionBody>
  </CAccordionItem>
  <CAccordionItem itemKey={4}>
    <CAccordionHeader>Tag Missing:</CAccordionHeader>
    <CAccordionBody>
    If the tag on the item is missing or ripped then the product will not be replaced.
    </CAccordionBody>
  </CAccordionItem>
  <CAccordionItem itemKey={5}>
    <CAccordionHeader>What's eligible for exchange?</CAccordionHeader>
    <CAccordionBody>
    If the item delivered to you is defective. If the item is broken at the time of accepting delivery, if some of the part is missing or plants are dead.
    </CAccordionBody>
  </CAccordionItem>
  <CAccordionItem itemKey={6}>
    <CAccordionHeader>Evaluate and Pick-Up:</CAccordionHeader>
    <CAccordionBody>
    <p>Hence we are providing decorated planter which is fragile, delicate by nature. We will recommend doing an unboxing video, which will help you to get the replacement in case of any damage.</p>
    <p>Once we receive the video, after evaluation Mystik fallsResolution team will connect with you within 24 hours regarding replacement process. Replacement is also subject to the availability of the product in our stock. If not available we will refund you for the same</p>
    <p>Exchange pick-up of the product will be scheduled based on your subsequent interaction with our Customer Support representative.</p>
    <p>Please retain the original packaging of the product, along with user manual and other accessories to ensure a successful pick-up.</p>
    <p>A product scheduled for replacement pick-up will be picked up by the logistic partner of Mystik falls from the original shipping address of the order, within 72 hours of initiation of the exchange pick-up request. No extra cost will be charged for the replacement.</p>
    </CAccordionBody>
  </CAccordionItem>
  <CAccordionItem itemKey={7}>
    <CAccordionHeader>Refund Process:</CAccordionHeader>
    <CAccordionBody>
    Refund policy will be only applicable if the product is lost in transit. You can choose to get your refund in a UPI Account or your bank accounts provided by you. Once the refund is initiated, the refund amount gets credited to your selected mode of payment <strong>in 7-10 business days.</strong>
    </CAccordionBody>
  </CAccordionItem>
  <CAccordionItem itemKey={8}>
    <CAccordionHeader>Late or Missing Refunds (If Applicable):</CAccordionHeader>
    <CAccordionBody>
    If you haven’t received a refund yet, first check the bank account again, it may take some time before your refund is officially posted.

Next contact your bank. There is often some processing time before a refund is posted. A refund generally takes 3-4 working days to reflect in your account.

If you’ve done all of this and you still have not received your refund yet, please contact us.
    </CAccordionBody>
  </CAccordionItem>
  <CAccordionItem itemKey={9}>
    <CAccordionHeader>Warranty Policy:</CAccordionHeader>
    <CAccordionBody>
    We don't have any warranty policy because it is a living plant and its Life period depends upon you.
    </CAccordionBody>
  </CAccordionItem>
</CAccordion>
    </div>
  )
}
