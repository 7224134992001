import React from 'react'
import { useNavigate } from 'react-router-dom'
import './Terms.scss'

export default function Terms() {
    const navigate = useNavigate()
  return (
    <div className='tc'>
      <h2>Terms and Conditions</h2>
      <p>Welcome to Mystik Falls! These terms and conditions outline the rules and regulations for the use of our website.</p>
      <p>By accessing this website, we assume you accept these terms and conditions. Do not continue to use Mystik Falls if you do not agree to all of the terms and conditions stated on this page.</p>
      <h3>1. Definitions</h3>
      <ul>
        <li>"Website" refers to Mystik Falls, accessible at https://mystikfalls.com/.</li>
        <li>"User" refers to any individual who accesses or uses the website.</li>
        <li>"Product(s)" refers to preserved flower resin jewellery, decorated planters, home decors, or any other items available for sale on the website.</li>
      </ul>
      <h3>2. Purchase and Sale</h3>.
      <ul>
        <li>Users can purchase products from the website without creating an account.</li>
        <li>To make a purchase, users are required to fill out their name, phone number, WhatsApp number, and location details for shipping purposes.</li>
        <li>Users must select a payment mode and complete the payment process to finalize their order.</li>
      </ul>
      <h3>3. Product Information</h3>
      <ul>
        <li>We strive to provide accurate product descriptions, images, and pricing information. However, we do not guarantee the accuracy, completeness, or reliability of any product information on the website.</li>
        <li>Colours and sizes of products may vary slightly due to the nature of materials and craftsmanship.</li>
      </ul>
      <h3>4. Shipping and Delivery</h3>
      <ul>
        <li>We aim to process and ship orders in a timely manner. However, delivery times may vary depending on the user's location and shipping method selected.</li>
        <li>Users are responsible for providing accurate shipping information. We are not liable for any delays or losses caused by incorrect shipping details provided by the user.</li>
      </ul>
      <h3>5. Returns and Refunds</h3>
      <ul>
        <li>We accept returns and offer refunds or exchanges for damaged or defective products within 1 day of delivery.</li>
        <li>for more information about Return Policy <a href='/tandc'><u >click here</u></a></li>
      </ul>
      <h3>6. Intellectual Property</h3>
      <ul>
        <li>All content, including images, text, logos, and designs, on the website is the intellectual property of Mystikfalls and is protected by copyright laws.</li>
        <li>Users are not permitted to use, reproduce, or distribute any content from the website without prior written consent from Mystikfalls.</li>
      </ul>
      <h3>7. Limitation of Liability</h3>
      <ul>
        <li>Mystikfalls shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with the use or inability to use the website or any products purchased from the website</li>
      </ul>
      <h3>8. Governing Law</h3>
      <ul>
        <li>These terms and conditions shall be governed by and construed in accordance with the laws of India, and any disputes relating to these terms and conditions shall be subject to the exclusive jurisdiction of the courts of India.</li>
      </ul>
      <h3>9. Changes to Terms and Conditions</h3>
      <ul>
        <li>Mystikfalls reserves the right to modify or replace these terms and conditions at any time without prior notice. By continuing to access or use the website after any revisions become effective, users agree to be bound by the updated terms and conditions.</li>
      </ul>
      <h3>10. Contact Us</h3>
      <ul>
        <li>If you have any questions about these terms and conditions, please contact us at mystikfalls2024@gmail.com or WhatsApp us.</li>
      </ul>
      <p>By using this website, you hereby consent to our terms and conditions and agree to abide by them.</p>
    </div>
  )
}
