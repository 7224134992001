import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { TbSearch } from "react-icons/tb";
import { CgShoppingCart } from "react-icons/cg";
import { MdBookmarkBorder } from "react-icons/md";
import "./Header.scss";
import Search from "../Header/Search/Search";
import { Context } from "../../utils/context";
import Cart from "../Cart/Cart";
import logo from '../../assets/nexinnide-logo.png'
import { CToastBody, CToast, CToastHeader, CToaster } from "@coreui/react";

const Header = () => {
    const [scrolled, setScrolled] = useState(false);
    const [searchModal, setSearchModal] = useState(false);
    const navigate = useNavigate();
    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 200) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
    }, []);

    const { cartCount, showCart, setShowCart } = useContext(Context);


    const reviews = [
        "Sudipta Gupta: This is a great product!",
        "Sonali Mukherjee: Loved the experience!",
        "Sarbani Roy: Would recommend to friends!",
        "Ritam Roy: Could be improved in some areas.",
        "Ranjana Sen: Amazing quality for the price!",
        "Suman Agarwal: Satisfied with the service.",
        "Nil Mitra: Best purchase I've made this year!",
        "Souminy Boiragi: Fast delivery and great support!",
        "Ranit Roy: Not what I expected, but still good.",
        "Akash Gangooly: Excellent customer service!"
    ];

    // State to keep track of the current review index
    const [currentIndex, setCurrentIndex] = useState(0);

    // useEffect hook to handle the timing logic
    useEffect(() => {
        // Set an interval to update the current index every 5 seconds
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
        }, 5000);

        // Clear the interval when the component unmounts
        return () => clearInterval(interval);
    }, [reviews.length]);


    return (
        <>
            <header
                className={`main-header ${scrolled ? "sticky-header" : ""}`}
            >
                <div className="header-content">
                    <ul className="left">
                        <li onClick={() => navigate("/")}>Home</li>


                    </ul>
                    <div className="center" onClick={() => navigate("/")}>
                        <img src={logo} alt="" />
                    </div>
                    <div className="right">
                        <TbSearch onClick={() => setSearchModal(true)} />
                        <li onClick={() => navigate("/blog")}>Blogs</li>
                        <span
                            className="cart-icon"
                            onClick={() => setShowCart(true)}
                        >
                            <CgShoppingCart />
                            {!!cartCount && <span>{cartCount}</span>}
                        </span>

                    </div>
                </div>
                <CToaster>
                    <CToast animation={true} autohide={false} visible={true} color='#dab88b'>
                        <CToastHeader color='black' closeButton>
                            <div className="fw-bold me-auto"><h4><b>Reviews</b></h4></div>
                        </CToastHeader>
                        <CToastBody color="black">{reviews[currentIndex]}</CToastBody>
                    </CToast>
                </CToaster>
            </header>
            {searchModal && <Search setSearchModal={setSearchModal} />}
            {showCart && <Cart />}

        </>
    );
};

export default Header;