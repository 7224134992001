import "./Products.scss";
import Product from "./Product/Product";
import { useState, useEffect } from "react";
import { fetchDataFromApi } from "../../utils/api";
import Loading from '../../assets/loading1.gif'


const Sale = ({ innerPage, headingText, flow }) => {
    const [products, setProducts] = useState()
    const [scrolled, setScrolled] = useState(false);
    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 150) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
    }, []);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getUserId();
},[]);

    const getUserId = () => {
        fetchDataFromApi(`/api/products?populate=*&[filters][discount]=${10}&[filters][discount]=${12}&[filters][discount]=${15}&[filters][discount]=${20}&[filters][discount]=${25}&[filters][discount]=${30}&[filters][discount]=${40}&[filters][discount]=${45}&[filters][discount]=${50}&[pagination][pageSize]=${1000}`)
            .then((res) => {
                setLoading(res);
                setProducts(res)
            });
    };
if(loading)

    return (
        <div className={`products-container ${flow ? "propspin" : ""}`} >
            {!innerPage && <div className="sec-heading">SALE ZONE</div>}
            <div className={`products ${innerPage ? "innerPage" : ""}`}>
                {products?.data?.map((item) => (
                    <Product
                        key={item.id}
                        id={item.id}
                        data={item.attributes}
                    />
                ))}
            </div>
        </div>
    );
    return(
    
        <div className="productL">
            <div className="sec">
        <img src={Loading} />
        <img src={Loading} />
        </div>
        <div className="sec">
        <img src={Loading} />
        <img src={Loading} />
        </div>
        <div className="sec">
        <img src={Loading} />
        <img src={Loading} />
        </div>
    </div>
    )
};

export default Sale;