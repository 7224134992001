import React from 'react'
import { COffcanvasBody, CButton, COffcanvas, COffcanvasHeader, COffcanvasTitle, CCloseButton, CDropdown, CDropdownMenu, CDropdownToggle, CDropdownItem, CDropdownDivider, CBadge, CAvatar } from '@coreui/react'
import { useState, useEffect } from 'react'
import './Card.scss'
import { RiPlantFill } from "react-icons/ri";
import { IoFlower } from "react-icons/io5";
import { GiSpiralBottle } from "react-icons/gi";
import { TbCandleFilled } from "react-icons/tb";
import { PiPlantFill } from "react-icons/pi";
import { TbBoxModel } from "react-icons/tb";
import { useNavigate } from 'react-router-dom';
import { BiSolidCategory } from "react-icons/bi";
import { IoIosBookmarks } from "react-icons/io";
import Avatar1 from '../../assets/Avatar1.jpg'
import Avatar2 from '../../assets/Avatar2.jpg'
import Avatar3 from '../../assets/Avatar3.jpg'
import Avatar4 from '../../assets/Avatar4.jpg'
import Avatar5 from '../../assets/Avatar5.jpg'
import Avatar6 from '../../assets/Avatar6.jpg'

export default function Card() {


  const [visible, setVisible] = useState(false)
  const navigate = useNavigate()
  return (
    <>
      <BiSolidCategory className='bar' onClick={() => setVisible(true)} cursor={'pointer'} />
      {/* <CButton color='warning' onClick={() => setVisible(true)}>Category</CButton> */}
      <COffcanvas backdrop="static" placement="start" visible={visible} onHide={() => setVisible(false)}>
        <COffcanvasHeader  >
          <COffcanvasTitle>Category</COffcanvasTitle>
          <CCloseButton className="text-reset" onClick={() => setVisible(false)} />
        </COffcanvasHeader>
        <COffcanvasBody>
          <ul className='cate'>
            {/* Frist dropdown */}
            <li>

              <CDropdown variant="btn-group">
                <CDropdownItem onClick={() => navigate('/category/1') | setVisible(false)}><h5><CAvatar src={Avatar1} /> Decorated Planters </h5></ CDropdownItem>
              </CDropdown>
            </li>
            {/* second menu */}
            <li>

              <CDropdown variant="btn-group">
                <CDropdownToggle color="" size="sm" split><h5><CAvatar src={Avatar2} /> Florista Collections</h5></CDropdownToggle>

                <CDropdownMenu>
                  <CDropdownItem onClick={() => navigate('/category/2') | setVisible(false)}>Pendent</CDropdownItem>
                  <CDropdownItem onClick={() => navigate('/category/3') | setVisible(false)}>Earring</CDropdownItem>
                  <CDropdownItem onClick={() => navigate('/category/4') | setVisible(false)}>Finger Ring</CDropdownItem>
                  {/* <CDropdownDivider /> */}
                  <CDropdownItem onClick={() => navigate('/category/6') | setVisible(false)}>Bracelet</CDropdownItem>

                </CDropdownMenu>
              </CDropdown>

            </li>
            <li>
            <CDropdown variant="btn-group">
              <CDropdownItem onClick={() => navigate('/category/9') | setVisible(false)}><h5><CAvatar src={Avatar3} /> Ela Collection <CBadge color="primary ms-auto">New</CBadge></h5></CDropdownItem>
            </CDropdown>
            </li>
            <li>
            <CDropdown variant="btn-group">
              <CDropdownItem onClick={() => navigate('/category/10') | setVisible(false)}><h5><CAvatar src={Avatar4} /> Bubble Love <CBadge color="primary ms-auto">New</CBadge></h5></CDropdownItem>
            </CDropdown>
            </li>
            <li>
              <h5 onClick={() => navigate('/category/5') | setVisible(false)}><CAvatar src={Avatar6} /> Glass Gardens </h5>
            </li>
            <li>
              <h5 onClick={() => navigate('/category/7') | setVisible(false)}><CAvatar src={Avatar5} /> Bookmark </h5>
            </li>
          </ul>
        </COffcanvasBody>
      </COffcanvas>
    </>
  )
}
