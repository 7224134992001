import { useNavigate } from "react-router-dom";
import "./Category.scss";
import { useState, useEffect } from "react";
import Loading from '../../../assets/loading2.gif'
import { fetchDataFromApi } from "../../../utils/api";

const Category = ({ categories }) => {
    const [scrolled, setScrolled] = useState(false);
    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 0.5) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
    }, []);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getUserId();
},[]);

    const getUserId = () => {
        fetchDataFromApi(`/api/categories`)
            .then((res) => {
                setLoading(res);
            });
    };
if(loading)


    return (
        <div className={`shop-by-category ${scrolled ? "cat" : ""}`}>
            <div className='categories'>
                {categories?.data?.map((item) => (
                    <div
                        key={item?.id}
                        className="category"
                        onClick={() => navigate(`/category/${item?.id}`)}
                    >
                        <img
                            // src={
                            //     process.env.REACT_APP_STRIPE_APP_DEV_URL +
                            //     item?.attributes?.img?.data?.attributes.url
                            // }
                            src={item?.attributes?.img?.data?.attributes.url}
                        />  
                        <span className="title">{item?.attributes.title}</span>
                    </div>
                ))}
            </div>
        </div>
    );
    return(
        <div className="catL">
        <img src={Loading} />
    </div>
    )
};

export default Category;