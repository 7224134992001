import React from 'react'
import './Terms.scss'
export default function Privacy() {
  return (
    <div className='tc'>
      <h2>Privacy Policy</h2>
      <p>'Mystik Falls' is committed to protecting the privacy of our users. This privacy policy outlines how we collect, use, disclose, and safeguard your personal information when you visit our website or make a purchase from us.</p>
      <h3>1. Information We Collect</h3>
      <ul>
        <li><b>Personal Information:</b> When you make a purchase on our website, we collect certain personal information from you, including your name, phone number, WhatsApp number, and shipping address.</li>
        <li><b>Payment Information: </b>  We may collect payment information such as credit card details or other payment details when you make a purchase. However, we do not store payment information on our servers. All payment transactions are processed securely through third-party payment processors.</li>
        <li><b>Usage Data:</b> We may also collect information about how you access and use our website, including your IP address, browser type, referring website, pages viewed, and other diagnostic data.</li>
      </ul>
      <h3>2. Use of Information</h3>
      <ul>
       <li>We use the personal information collected from you to process your orders, communicate with you about your orders, and provide customer support.</li>
       <li>We may also use your personal information to improve our website, personalize your experience, and analyze trends and user behavior.</li>
       <li>We do not sell, trade, or rent your personal information to third parties for marketing purposes.</li>
      </ul>
      <h3>3. Disclosure of Information</h3>
      <ul>
        <li>We may disclose your personal information to third-party service providers who assist us in operating our website, conducting our business, or providing services to you. These third-party service providers are obligated to keep your information confidential and are only permitted to use your personal information for the purposes specified by us.</li>
        <li>We may also disclose your personal information if required to do so by law or in response to valid legal requests from government authorities.</li>
      </ul>
      <h3>4. Data Security</h3>
      <ul>
        <li>We take appropriate measures to protect the security of your personal information and prevent unauthorized access, disclosure, alteration, or destruction of data.</li>
        <li>However, please be aware that no method of transmission over the internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</li>
      </ul>
      <h3>5. Your Rights</h3>
      <ul>
        <li>You have the right to access, update, or delete your personal information at any time. If you would like to do so, please contact us at mystikfalls2024@gmail.com or WhahtsApp us.</li>
        <li>You also have the right to opt out of receiving marketing communications from us. You can unsubscribe from our marketing emails by following the instructions provided in the email.</li>
      </ul>
      <h3>6. Changes to Privacy Policy</h3>
      <ul>
        <li>We reserve the right to update or modify this privacy policy at any time. Any changes will be posted on this page with a revised effective date.</li>
        <li>Your continued use of our website after any changes to this privacy policy constitutes your acceptance of the changes.</li>
      </ul>
      <h3>7. Contact Us</h3>
      <ul>
        <li>If you have any questions or concerns about our privacy policy, please contact us at mystikfalls2024@gmail.com or WhatsApp us.</li>
      </ul>
      <p>By using our website, you consent to the terms of this privacy policy.</p>
    </div>
  )
}
