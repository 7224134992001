import React from "react";
import {
    FaFacebookF,
    FaWhatsapp,
    FaInstagram,
    FaYoutube,
} from "react-icons/fa";
import "./Newsletter.scss";
import { WhatsappShareButton } from "react-share";
const Newsletter = () => {
    return (
        <div className="newsletter-section">
            <div className="newsletter-content">
                <span className="small-text">Newsletter</span>
                <span className="big-text">
                    Welcome to Mystik Falls
                </span>
                <span className="text">
                    Start your journey with us
                </span>
                <span className="social-icons">
                    <div className="icon">
                    <a href="https://www.facebook.com/yours.mystikfalls"><FaFacebookF size={14} /></a>
                    </div>
                    <div className="icon">
                    <WhatsappShareButton  title="Mystik Falls" description='BUY NOW'  url="http://mystikfalls.com" > <FaWhatsapp size={14} /></WhatsappShareButton>
                    </div>
                    <div className="icon">
                    <a href="https://www.instagram.com/mystik.falls?igsh=M3BobXI5bjM5N3Fm"> <FaInstagram /></a>
                    </div>
                    <div className="icon">
                        <a href="https://www.youtube.com/@MystikFalls"><FaYoutube/></a>
                    </div>
                </span>
            </div>
        </div>
    );
};

export default Newsletter;