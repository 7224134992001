import React, { useEffect, useContext, useState } from "react";
import "./Home.scss";
import Banner from "./Banner/Banner";
import Category from "./Category/Category";
import Products from "../Products/Products";
import Recard from "../Review/Recard";
import Pro from "../Products/Pro";
import { fetchDataFromApi } from "../../utils/api";
import useFetch from "../../hooks/useFetch";
import { Context } from "../../utils/context";
import { useParams } from "react-router-dom";
import { TbCategoryFilled } from "react-icons/tb";
import Card from '../Card/Card';
import Banner2 from "./Banner2/Banner2";
import Ad from '../Ad/Ad'
import AD1 from '../../assets/ad1.1.gif'
import AD2 from '../../assets/Ad1.jpg';
import Banner3 from "./Banner4/Banner4";

const Home = () => {
    const { id } = useParams();
    const [pro, setPro] = useState();
    const [pro1, setPro1] = useState();
    const [pro2, setPro2] = useState();
    const [pro3, setPro3] = useState();
    const { products, setProducts, categories, setCategories } =
        useContext(Context);
    useEffect(() => {
        getProducts();
        getCategories();
        getPro();
        getPro1();
        getPro2();
        getPro3();
    }, []);

    const getProducts = () => {
        fetchDataFromApi("/api/products?populate=*").then((res) => {
            setProducts(res);
        });
    };
    const getCategories = () => {
        fetchDataFromApi("/api/categories?populate=*").then((res) => {
            setCategories(res);
        });
    };
    const getPro = () => {
        fetchDataFromApi("/api/products?populate=*&[filters][categories][id]=1").then((res) => {
            setPro(res);
        });
    };
    const getPro1 = () => {
        fetchDataFromApi("/api/products?populate=*&[filters][categories][id]=2").then((res) => {
            setPro1(res);
        });
    };
    const getPro2 = () => {
        fetchDataFromApi("/api/products?populate=*&[filters][categories][id]=4").then((res) => {
            setPro2(res);
        });
    };
    const getPro3 = () => {
        fetchDataFromApi("/api/products?populate=*&[filters][categories][id]=5").then((res) => {
            setPro3(res);
        });
    };
    // const { data } = useFetch(`/api/categories?populate=*`);


    // if (!data) return;
    // const product = data?.data?.[0]?.attributes.products;

    
    // var {data}= useFetch(
    //     `/api/products?populate=*&[filters][categories][id]=2`
    // );

    return (
        <div>

            <Banner3 />
            <div className="main-content">
                <div className="AD">
                    <img src={AD1} />
                </div>
                <Ad />
                {/* <Card/> */}
                <div className="layout">
                    <Category categories={categories} />
                </div>
                <Card /><p className="cat">Category</p>
                <Banner2 />
                <div className="layout">
                    <div>
                        <div className='mainbar'>
                            <h1>Decorative Planters</h1>
                            <br />
                            <p><em>Decorative planters add a touch of elegance and style to any indoor or outdoor space. With a variety of shapes, sizes, and finishes, they showcase your plants beautifully while enhancing your decor. From modern to traditional designs, decorative planters create a charming focal point and bring nature closer to home.</em></p>
                        </div>
                        <div className='down'>
                            <div className='downbar'>


                            </div>
                            <div className='proscroll'>
                            <Pro products={pro}  />
                                 </div>
                                 
                        </div>
                        
                    </div>
                    <div>
                        <div className='mainbar'>
                        <h1>Preserved Flower Resin Jewellery</h1>
                            
                            <p><em>Preserved flower resin jewelry captures the beauty of nature, combining delicate blooms with a touch of love. Each piece showcases vibrant colors and intricate designs, creating a timeless keepsake. Perfect for expressing affection, these unique accessories celebrate the elegance of flowers and make heartfelt gifts.</em></p>
                        </div>
                        <div className='down'>
                            <div className='downbar'>


                            </div>
                            <div className='proscroll'>
                            <Pro products={pro1}  />
                                 </div>
                                 
                        </div>
                        </div>
                        <div>
                        <div className='mainbar'>
                        <h1>Preserved Flower Resin Finger Ring</h1>
                            
                            <p><em>Preserved flower resin finger rings capture the beauty of nature with a touch of love. Each ring features delicate blooms set in clear resin, creating a unique, vibrant accessory. These enchanting rings are perfect for expressing affection and make heartfelt gifts for any occasion.</em></p>
                        </div>
                        <div className='down'>
                            <div className='downbar'>


                            </div>
                            <div className='proscroll'>
                            <Pro products={pro2}  />
                                 </div>
                                 
                        </div>
                        </div>
                        <div>
                        <div className='mainbar'>
                        <h1>Glass Garden</h1>
                            
                            <p><em>This small glass bottles holds a world of wonder with a mix of preserved flowers floating in a gentle solution. Its pure, lovable charm brings a touch of nature's beauty to your space. Perfect for gifting or personal enjoyment, this exquisite piece is a delicate expression of love and elegance.</em></p>
                        </div>
                        <div className='down'>
                            <div className='downbar'>


                            </div>
                            <div className='proscroll'>
                            <Pro products={pro3}  />
                                 </div>
                                 
                        </div>
                        </div>
                    <Products
                        headingText="Popular Products"
                        products={products}
                    />
                    <div className="AD">
                        <img src={AD2} />
                    </div>
                    <div>
                        <Recard/>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Home;