import React from "react";
import "./Footer.scss";
import { FaLocationArrow, FaMobileAlt, FaEnvelope } from "react-icons/fa";
import { COffcanvas, COffcanvasBody, COffcanvasTitle, COffcanvasHeader, CCloseButton } from "@coreui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Payment from '../../assets/payments.png'

const Footer = () => {
    const [visible, setVisible] = useState(false)
    const navigate = useNavigate()
    return (
        <div className="footer">
            <div className="footer-content">
                <div className="col">
                    <div className="title" onClick={() => setVisible(true)}>About</div>
                    <COffcanvas placement="start" visible={visible} onHide={() => setVisible(false)}>
                            <COffcanvasHeader>
                                <COffcanvasTitle>About</COffcanvasTitle>
                                <CCloseButton className="text-reset" onClick={() => setVisible(false)} />
                            </COffcanvasHeader>
                            <COffcanvasBody className="about-text">
                                <p>In Mystik Falls the vision is to embed nature into modern lifestyle. Where a small piece of nature in the form of dried flowers or plants or leaf can sooth our soul.</p>
                                <p>This is your one stop destination for wearable botanics that has the perfect blend of aesthetics, fashion, nature, magic and mystical stories. Each piece of jewellery  in our collection is precisely handcrafted, with wild or our garden grown flowers, leaves, seeds collected  by skilled artisans to preserve its natural beauty. It is a matter of  immense pleasure that we are able to offer you a unique kind of jewellery  and splendid shopping experience, starting from the second you enter into our website, choose your piece of nature inspired piece. We are overwhelmed to share the joy of when you unveil our carefully packed box just for you.</p>
                                <p><b>Commitment towards quality:</b> Quality is the core of everything we create. So we ensure your trust is in the good hands of our artisan ensuring that every petal, leaf can reflect your elegance.  Each piece is made with precision, details and utmost attention. 	So you can carry the essence of beautiful  nature wherever you go.</p>
                            </COffcanvasBody>
                        </COffcanvas>
                </div>
                <div className="col">
                    <div className="title">Contact</div>
                    <div className="c-item">
                        <FaMobileAlt />
                        <div className="text">Phone: 97333 44618</div>
                    </div>
                    <div className="c-item">
                        <FaEnvelope />
                        <div className="text">Email: mystikfalls2024@gmail.com</div>
                    </div>
                </div>
                <div className="col">
                    <div className="title">Categories</div>
                    <span className="text" onClick={()=>navigate('/category/1')}>Decorated Planters</span>
                    <span className="text" onClick={()=>navigate('/category/2')}>Pendent</span>
                    <span className="text" onClick={()=>navigate('/category/3')}>Ear Ring</span>
                    <span className="text" onClick={()=>navigate('/category/4')}>Finger Ring</span>
                    <span className="text" onClick={()=>navigate('/category/6')}>Bracelet</span>
                    <span className="text" onClick={()=>navigate('/category/5')}>Glass Garden</span>
                    <span className="text" onClick={()=>navigate('/category/7')}>Bookmark</span>
                </div>
                <div className="col">
                    <div className="title">Pages</div>
                    <span className="text" onClick={()=>navigate('/')}>Home</span>
                    <span className="text" onClick={() => setVisible(true)}>About</span>
                    <span className="text" onClick={()=>navigate('/privacypolicy')}>Privacy Policy</span>
                    <span className="text" onClick={()=>navigate('/termsandconditions')}>Terms & Conditions</span>
                    <span className="text">Contact Us</span>
                </div>
            </div>
            <div className="bottom-bar">
                <div className="bottom-bar-content">
                    <span className="text">
                    &#169; Copyright @2024 MYSTIKFALLS | All Rights Reserved. 
                    </span>
                    <img src={Payment} />
                </div>
            </div>
        </div>
    );
};

export default Footer;