import React from "react";
import { useNavigate } from "react-router-dom";
import "./Product.scss";
import Rating from '@mui/material/Rating';
import { CBadge } from "@coreui/react";
import { WhatsappShareButton } from "react-share";
import ReactWhatsapp from "react-whatsapp";
import { IoLogoWhatsapp } from "react-icons/io5";
import Avatar from '../../../assets/Mystik Falls.png'

import { useState } from "react";
import { useEffect } from "react";

const Product = ({ data, id }) => {
    const navigate = useNavigate();



    return (
        
                <>
                    {!data.stock > 0 ?

                        <div
                            className="product-card1"

                        >

                            <div className="thumbnail">
                                <div className="stock"><p>Out of Stock</p></div>
                                <img
                                    // src={
                                    //     process.env.REACT_APP_STRIPE_APP_DEV_URL +
                                    //     data?.img?.data?.[0]?.attributes.url
                                    // }
                                    src={data?.img?.data?.[0]?.attributes?.url}
                                />
                                {/* {console.log(data)} */}
                            </div>
                            <div className="prod-details">
                                <span className="name">{data?.title}</span>
                                <span className="name">
                                    <>
                                        {!data.preprice ?
                                            <></>
                                            : <span className="preprice">&#8377;{data?.preprice}<CBadge color="danger ms-auto">{data?.discount}%</CBadge></span>
                                        }
                                    </>
                                </span>
                                <span className="price">&#8377;{data?.price}</span>
                            </div>
                            <span><Rating value={data?.rating} precision={0.5} /></span>
                            <ReactWhatsapp className="right" number="9733344618" message="I want to know about this product" avatar={Avatar} color="warning" url={"https://mystikfalls.com/product/" + (id)}>
                                Enquary Now
                            </ReactWhatsapp>
                        </div>
                        :
                        <div
                            className="product-card"
                            onClick={() => navigate("/product/" + id)}
                        >
                            {/* {console.log(data.stock)} */}
                            <div className="thumbnail">
                                <img
                                    // src={
                                    //     process.env.REACT_APP_STRIPE_APP_DEV_URL +
                                    //     data?.img?.data?.[0]?.attributes.url
                                    // }
                                    src={data?.img?.data?.[0]?.attributes?.url}
                                />
                                {/* {console.log(data)} */}
                            </div>
                            <div className="prod-details">
                                <span className="name">{data?.title}</span>
                                <span className="name">
                                    <>
                                        {!data.preprice ?
                                            <></>
                                            : <span className="preprice">&#8377;{data?.preprice}<CBadge color="danger ms-auto">{data?.discount}%</CBadge></span>
                                        }
                                    </>
                                </span>
                                <span className="price">&#8377;{data?.price}</span>
                            </div>
                            <>
                                {!data.rating > 0 ?
                                    <></>
                                    :
                                    <span><Rating value={data?.rating} precision={0.5} /></span>
                                }
                            </>
                        </div>


                    }</>

    );
    
};

export default Product;