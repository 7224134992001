import React from 'react'
import "./Banner2.scss";
import Banner from '../../../assets/Plan.jpg'
import Banner3 from '../../../assets/Plan1.jpg'
import Banner1 from '../../../assets/Plan2.jpg'
import { CCarousel, CCarouselItem, CImage } from '@coreui/react';

export default function Banner2() {
  return (
    <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                    <CCarousel controls transition="crossfade">
  <CCarouselItem>
    <CImage className="d-block w-100" src={Banner3} alt="slide 1" />
  </CCarouselItem>
  <CCarouselItem>
    <CImage className="d-block w-100" src={Banner1} alt="slide 2" />
  </CCarouselItem>
  <CCarouselItem>
    <CImage className="d-block w-100" src={Banner} alt="slide 3" />
  </CCarouselItem>
</CCarousel>
                </div>
  )
}
