import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import { useEffect, useContext, useState } from "react";
import Header from "./components/headers/Header.jsx";
import Footer from "./components/Footer/Footer.jsx";
import Home from "./components/Home/Home.jsx";
import Category from "./components/Category/Category.jsx";
import SingleProduct from "./components/SingleProduct/SingleProduct.jsx";
import Newsletter from "./components/Footer/Newsletter/Newsletter.jsx";
import AppContext from "./utils/context";
import Order from "./components/Order/Order1.jsx";
import Tc from "./components/Tc/Tc.jsx";
import Sucsses from "./components/Sucsses.jsx";
import { Context } from "./utils/context.js";
import { fetchDataFromApi } from "./utils/api";
import Loading from "./components/Loading/Loading.jsx";
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import avatar from './assets/Mystik Falls.png'
import Blog from "./components/Blog/Blog.jsx";
import Review from "./components/Review/Review";
import Privacy from "./components/Tc/Privacy.jsx";
import Terms from "./components/Tc/Terms.jsx";
import RazorpayComponent from "./components/Rp/Razorpay";
import Sale from "./components/Products/Sale.jsx";

function App() {
 
// if (loading)



    return (
        <BrowserRouter>
            <AppContext>
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/category/:id" element={<Category />} />
                    <Route path="/product/:id" element={<SingleProduct />} />
                    <Route path="/order" element={<Order />} />
                    <Route path="/tandc" element={<Tc />} />
                    <Route path="/sucsses" element={<Sucsses />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/status" element={<Review />} />
                    <Route path="/privacypolicy" element={<Privacy />} />
                    <Route path="/termsandconditions" element={<Terms />} />
                    <Route path="/payment" element={<RazorpayComponent />} />
                    <Route path="/sale" element={<Sale />} />
                </Routes>
                <FloatingWhatsApp phoneNumber='9733344618' accountName='Mystik Falls' avatar={avatar} statusMessage='Typically replies within 3 hour'/>
                <Newsletter />
                <Footer />
            </AppContext>
        </BrowserRouter>
    );
  
    
}

export default App;