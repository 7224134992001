import { useContext, useState } from "react";
import { Context } from "../../utils/context";
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import RelatedProducts from "./RelatedProducts/RelatedProducts";
import {
    FaCartPlus,
} from "react-icons/fa";
import "./SingleProduct.scss";
import { CBadge, CButton, CCarousel, CCarouselItem, CImage } from "@coreui/react";
import '@coreui/coreui/dist/css/coreui.min.css'
import Rating from '@mui/material/Rating';
import { WhatsappShareButton } from "react-share";
import { FaWhatsapp } from "react-icons/fa";
import ReactWhatsapp from "react-whatsapp";
import Avatar from '../../assets/Mystik Falls.png'



const SingleProduct = () => {
    const [quantity, setQuantity] = useState(1);
    const { id } = useParams();
    const { handleAddToCart } = useContext(Context);
    const { data } = useFetch(`/api/products?populate=*&[filters][id]=${id}`);

    const decrement = () => {
        setQuantity((prevState) => {
            if (prevState === 1) return 1;
            return prevState - 1;
        });
    };
    const increment = () => {
        setQuantity((prevState) => prevState + 1);
    };

    if (!data) return;
    const product = data?.data?.[0]?.attributes;
    const care = product.care
    return (
        <div className="single-product-main-content">
            <div className="layout">
                <div className="single-product-page">
                    <div className="left">
                        <CCarousel controls indicators>
                            <CCarouselItem>
                                <CImage className="d-block w-100" src={product?.img?.data?.[0]?.attributes.url} alt="slide 1" />
                            </CCarouselItem>
                            <CCarouselItem>
                                <CImage className="d-block w-100" src={product?.img?.data?.[1]?.attributes.url} alt="slide 2" />
                            </CCarouselItem>
                            <CCarouselItem>
                                <CImage className="d-block w-100" src={product?.img?.data?.[2]?.attributes.url} alt="slide 3" />
                            </CCarouselItem>
                            {!product?.img?.data?.[3]?.attributes.url ?
                            <CCarouselItem>
                            <CImage className="d-block w-100" src={product?.img?.data?.[0]?.attributes.url} alt="slide 3" />
                        </CCarouselItem>
                            :
                            <CCarouselItem>
                                <CImage className="d-block w-100" src={product?.img?.data?.[3]?.attributes.url} alt="slide 3" />
                            </CCarouselItem>
}
{!product?.img?.data?.[4]?.attributes.url ?
    <CCarouselItem>
                                <CImage className="d-block w-100" src={product?.img?.data?.[1]?.attributes.url} alt="slide 3" />
                            </CCarouselItem>
:
                            <CCarouselItem>
                                <CImage className="d-block w-100" src={product?.img?.data?.[4]?.attributes.url} alt="slide 3" />
                            </CCarouselItem>}
                            {!product?.img?.data?.[5]?.attributes.url ?
                            <CCarouselItem>
                            <CImage className="d-block w-100" src={product?.img?.data?.[2]?.attributes.url} alt="slide 3" />
                        </CCarouselItem>
                            :
                            <CCarouselItem>
                                <CImage className="d-block w-100" src={product?.img?.data?.[5]?.attributes.url} alt="slide 3" />
                            </CCarouselItem>}
                        </CCarousel>
                        {/* <img
                            // src={
                            //     process.env.REACT_APP_STRIPE_APP_DEV_URL +
                            //     product.img?.data[0]?.attributes.url
                            // }
                            
                        /> */}
                    </div>
                    <div className="right">
                        <span className="name">{product.title}</span>
                        <>
                    {!product.rating > 0 ?
                    <></>
                    :
                    <span><Rating value={product?.rating} precision={0.5} /></span>
                    }
                    </>
                        <>
                            {!product.preprice ?
                                <></>
                                : <span className="preprice">&#8377;{product?.preprice}<CBadge color="danger ms-auto">{product?.discount}%</CBadge></span>
                            }
                        </>
                        <span className="price">&#8377;{product.price}</span>
                            <span ><p>* including onetime delivery charge</p></span>
                        <span className="desc">{product.desc}</span>

                        <div className="cart-buttons">
                            <div className="quantity-buttons">
                                {/* <span onClick={decrement}>-</span>
                                <span>{quantity}</span>
                                <span onClick={increment}>+</span> */}
                            </div>
                            <>
                                {!quantity < product.stock ?
                                    <button
                                        className="add-to-cart-button"
                                        onClick={() => {
                                            handleAddToCart(data?.data?.[0], quantity);
                                            setQuantity(1);
                                        }}
                                    >
                                        <FaCartPlus size={20} />
                                        ADD TO CART
                                    </button>
                                    :
                                    <></>
                                }
                            </>
                            
                        </div>
                        <div className="wa-button">
                        <ReactWhatsapp className="center" number="9733344618" message="I want to buy this product" avatar={Avatar} color="warning" url={"https://mystikfalls.com/product/" + (id)}>
                        Buy from WhatsApp <FaWhatsapp/>
                    </ReactWhatsapp>
                    </div>
                        <span className="divider" />
                        <div className="info-item">
                            <span className="text-bold">
                                <h3>Category:{" "}</h3>
                                <span>
                                    {
                                        product.categories.data[0].attributes
                                            .title
                                    }
                                </span>
                            </span>
                            <span className="desc">
                                <h4>Constituents</h4>
                                <p>{product.constituents?.[0].children?.[0]?.children?.[0].text}</p>
                                <p>{product.constituents?.[0].children?.[1]?.children?.[0].text}</p>
                                <p>{product.constituents?.[0].children?.[2]?.children?.[0].text}</p>
                                <p>{product.constituents?.[0].children?.[3]?.children?.[0].text}</p>
                                <p>{product.constituents?.[0].children?.[4]?.children?.[0].text}</p>
                                <p>{product.constituents?.[0].children?.[5]?.children?.[0].text}</p>
                            </span>
                            <span className="desc">
                                <h4>Specifications</h4>
                                <p>{product.size?.[0].children?.[0]?.children?.[0].text}</p>
                                <p>{product.size?.[0].children?.[1]?.children?.[0].text}</p>
                                <p>{product.size?.[0].children?.[2]?.children?.[0].text}</p>
                                <p>{product.size?.[0].children?.[3]?.children?.[0].text}</p>
                                <p>{product.size?.[0].children?.[4]?.children?.[0].text}</p>
                                <p>{product.size?.[0].children?.[5]?.children?.[0].text}</p>
                            </span>
                            <span className="desc">
                                <h4>Care Instructions</h4>
                                <p>{care[0].children?.[0]?.children?.[0].text}</p>
                                <p>{care[0].children?.[1]?.children?.[0].text}</p>
                                <p>{care[0].children?.[2]?.children?.[0].text}</p>
                                <p>{care[0].children?.[3]?.children?.[0].text}</p>
                                <p>{care[0].children?.[4]?.children?.[0].text}</p>
                                <p>{care[0].children?.[5]?.children?.[0].text}</p>
                            </span>
                    <span><p>Our products are hand crafted and some of living plants.The specifications and the given picture can differ a little.</p></span>
                    <span>{product.term}</span>
                        </div>
                        <span><CButton><WhatsappShareButton  title="Check this amazing product" description='Buy Product'  url={"https://mystikfalls.com/product/" + (id)}  >Share On <FaWhatsapp size={14} /></WhatsappShareButton></CButton></span>
                    </div>
                </div>
                <RelatedProducts
                    productId={id}
                    categoryId={product.categories.data[0].id}
                />
            </div>
        </div>
    );
};

export default SingleProduct;