import React from 'react'
import './Banner4.scss';
import sale from '../../../assets/Sale.gif'
import { useNavigate } from 'react-router-dom';

export default function Banner4() {
    const navigate = useNavigate()
  return (
    <div className="image-container">
    <img src={sale} alt="Example" className="image" />
    <button className="button" onClick={()=> navigate('/sale')}>Check Now</button>
  </div>
  )
}
