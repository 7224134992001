import React from 'react'
import './Recard.scss'
import { CCard, CCardHeader, CCardBody, CImage } from '@coreui/react'
import { Rating } from '@mui/material'
import ReviewA from "../../assets/REVEIWS.jpeg"
import ReviewB1 from '../../assets/Review1 (1).jpg'
import ReviewB2 from '../../assets/Review1 (2).jpg'

export default function Recard() {
    return (
        <div><div className='re'>
        <CCard className="w-100" color='dark' textColor='white'>
            <CCardHeader color='primary'>Review</CCardHeader>
            <div className='ph'>
            <CImage src={ReviewA}/>
            </div>
            <CCardBody>
                <blockquote className="blockquote mb-0">
                    <p><em>Brought: Glass Garden</em></p>
                    <br />
                    <p>Thank you so much for this beautiful piece of art. Ami jerok pic dekhechhi same jinis peyechhi...just mind boggling...He liked so much...he told me that an unique gift</p>
                <footer><em>- Ishita Chatterjee</em></footer>
                </blockquote>
                <Rating value={5} precision={0.5} />
            </CCardBody>
        </CCard>
        </div>
        <div className='re'>
        <CCard className="w-100" color='dark' textColor='white'>
            <CCardHeader color='primary'>Review</CCardHeader>
            <div className='ph'>
            <CImage src={ReviewB1}/>
            <CImage src={ReviewB2}/>
            </div>
            <CCardBody>
                <blockquote className="blockquote mb-0">
                    <p><em>Brought: Ela Pendents</em></p>
                    <br />
                    <p>Thank you, Ela collection khub bhalo laglo, packaging khub bhalo chilo. Just collect kore unbox korlam.Candle ta khub bhalo laglo.</p>
                <footer><em>- Susmita Ghatuary</em></footer>
                </blockquote>
                <Rating value={5} precision={0.5} />
            </CCardBody>
        </CCard>
        </div>
        <div className='re'>
            <CCard className="w-100" color='dark' textColor='white'>
                <CCardHeader color='primary'>Review</CCardHeader>
                <CCardBody>
                    <blockquote className="blockquote mb-0">
                        <p><em>Brought: Sky Blue Bubble Love Pendent</em></p>
                        <br />
                        <p>Preserved flower resin jewelry features vibrant flowers in clear resin, offering unique, elegant accessories perfect for gifts or special occasions.</p>
                    <footer><em>- Susmita Dey</em></footer>
                    </blockquote>
                    <Rating value={4.3} precision={0.5} />
                </CCardBody>
            </CCard>
            </div>
            <div className='re'>
            <CCard className="w-100" color='dark' textColor='white'>
                <CCardHeader>Review</CCardHeader>
                <CCardBody>
                    <blockquote className="blockquote mb-3">
                    <p><em>Brought: Blooming Flower Pendent</em></p>
                        <br />
                        <p>Besh sundor. Erokom jinis prothom bar try korlam bhalo laglo...</p>
                    <footer><em>- Ranita Mitra</em></footer>
                    </blockquote>
                    <Rating value={4.3} precision={0.5} />
                </CCardBody>
            </CCard>
            </div>
            <div className='re'>
            <CCard className="w-100" color='dark' textColor='white'>
                <CCardHeader color='primary'>Review</CCardHeader>
                <CCardBody>
                    <blockquote className="blockquote mb-0">
                        <p><em>Brought: Buddha Tree Decorative Planter</em></p>
                        <br />
                        <p>Charming and elegant, adds a touch of nature on my desk. The Buddha makes me feel so nice when i get depressed</p>
                        <footer><em>- Jayanti Santra</em></footer>
                    </blockquote>
                    <Rating value={4} precision={0.5} />
                </CCardBody>
            </CCard>
            </div>
            <div className='re'>
            <CCard className="w-100" color='dark' textColor='white'>
                <CCardHeader>Review</CCardHeader>
                <CCardBody>
                    <blockquote className="blockquote mb-3">
                    <p><em>Brought: Coastal Elegance Oasis Glass Garden</em></p>
                        <br />
                        <p>Never seen this type of product before but this is amazing!! the flowers are like floating inside. I will gift it to my wife on our anniversary</p>
                    <footer><em>- Jayjit Sen</em></footer>
                    </blockquote>
                    <Rating value={4.5} precision={0.5} />
                </CCardBody>
            </CCard>
            </div>
        </div>
    )
}
