import { useContext, useState } from "react";
import "./Order1.scss";
import FormInput from "./From/FormInput";
import { Context } from "../../utils/context";
import CartItem from "../Cart/CartItem/CartItem";
import { CCol, CFormCheck, CFormInput, CFormSelect } from "@coreui/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";


export default function Order1() {
  const navigate = useNavigate()
  const [pay, setPay] = useState()
  const [Pname, setPname] = useState()
  const [Pphone, setPphone] = useState()
  const [Card, setCard] = useState()
  const { cartItems, cartSubTotal } = useContext(Context);
  const [values, setValues] = useState({
    name: "",
    email: "",
    // birthday: "",
    number: "",
    // confirmPassword: "",
    whatsapp: "",
    pname: Pname,
    pphone: Pphone,
    card: Card,
    products: cartItems,
    price: cartSubTotal,
    pay: pay,
    address: "",
  });


  const handlePayment = async (e) => {

    e.preventDefault();
    await axios
      .post(
        `https://mystikback.onrender.com/api/orders`,
        {
          data: {
            name: values.name,
            phone: values.number,
            whatsapp: values.whatsapp,
            email: values.email,
            pname: Pname,
            pphone: Pphone,
            card: Card,
            address: values.address,
            pay: pay,
            products: cartItems,
            price: cartSubTotal,
          }
        }
       
      )
      // .then((toast.success("Order Confirm", {
      //   hideProgressBar: false,
        .then(navigate('/sucsses'))
        .then((window.location.reload(true)))
      // })))

      .catch((error) => {
        console.log(error)
        // navigate("/")
      })


  }

  const inputs = [
    {
      id: 1,
      name: "name",
      type: "text",
      placeholder: "Name",
      errorMessage:
        "Name should be 3-30 characters and shouldn't include any special character!",
      label: "Name*",
      pattern: "^[A-Za-z0-9 {3,30}$",
      required: true,
    },
    {
      id: 2,
      name: "email",
      type: "email",
      placeholder: "Email",
      errorMessage: "It should be a valid email address!",
      label: "Email*",
      required: true,
    },
    // {
    //   id: 3,
    //   name: "birthday",
    //   type: "date",
    //   placeholder: "Birthday",
    //   label: "Birthday",
    // },
    {
      id: 4,
      name: "number",
      type: "text",
      placeholder: "Number",
      errorMessage:
        "Enter correct phone number",
      label: "Number*",
      pattern: `^[0-9]{10,20}$`,
      required: true,
    },
    // {
    //   id: 5,
    //   name: "confirmPassword",
    //   type: "password",
    //   placeholder: "Confirm Password",
    //   errorMessage: "Passwords don't match!",
    //   label: "Confirm Password",
    //   pattern: values.password,
    //   required: true,
    // },
    {
      id: 5,
      name: "whatsapp",
      type: "text",
      placeholder: "WhatsApp Number",
      errorMessage:
        "Enter correct WhatsApp number",
      label: "WhatsApp Number*",
      pattern: `^[0-9]{10,20}$`,
      required: true,
    },
    // {
    //   id: 6,
    //   name: "pname",
    //   type: "text",
    //   placeholder: "Person's Name",
    //   errorMessage:
    //     "Name should be 3-16 characters and shouldn't include any special character!",
    //   label: "Gifting Person's Name",
    //   pattern: "^[A-Za-z0-9]{3,16}$",
    //   required: false,
    // },
    // {
    //   id: 7,
    //   name: "pphone",
    //   type: "text",
    //   placeholder: "Person's Number",
    //   errorMessage:
    //     "Enter correct phone number",
    //   label: "Gifting person's phone number",
    //   pattern: `^[0-9]{10,20}$`,
    //   required: false,
    // },
    // {
    //   id: 8,
    //   name: "card",
    //   type: "text",
    //   placeholder: "Write something for your love one",
    //   errorMessage:
    //     "Card should be 4-500 characters!",
    //   label: "Gifting Person's Name",
    //   pattern: "^[A-Za-z0-9!-_@#$%^&*?.,]{4,500}$",
    //   required: false,
    // },
    {
      id: 6,
      name: "address",
      type: "text",
      placeholder: "Enter full address for flawless delivery",
      errorMessage:
        "Card should be 4-1500 characters!",
      label: "Address*",
      pattern: "^[A-Za-z0-9!-_@#$%^&*?., ]{4,1500}$",
      required: true,
      style:{ height: 100 + 'px' }
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  return (
    <div className="app1">
      <form onSubmit={handlePayment}>
        <h1>Order</h1>
        <CartItem />
        <div className="subtotal">
          <span className="text">Subtotal:</span>
          <span className="text total">
            &#8377;{cartSubTotal}
          </span>
        </div>
        <br />
        {inputs.map((input) => (
          <FormInput
            key={input.id}
            {...input}
            value={values[input.name]}
            onChange={onChange}
          />
        ))}
        <h4>Want To Gift Someone?</h4>
        <p>* Rs. 20/- charge will be added for the special packaging and card at the time of payment</p>

        <CCol md={6}>
          <CFormInput type="text" id="validationDefault03" label="Person Name" placeholder="Person's Name"
            value={Pname}
            onChange={(e) => setPname(e.target.value)} />
        </CCol>
        <CCol md={6}>
          <CFormInput type="number" id="validationDefault03" label="Person Phone Number" placeholder="Person's Phone Number"
            value={Pphone}
            onChange={(e) => setPphone(e.target.value)} />
        </CCol>
        <CCol md={6}>
          <h6>Writting Card</h6>
          <textarea className="form-control" placeholder="Write something for the person you want to send this product" id="floatingTextarea2" style={{ height: 100 + 'px' }} value={Card}
            onChange={(e) => setCard(e.target.value)}></textarea>
        </CCol>
        <br />
        <h4>Choose your payment options</h4>
        {/* <CFormSelect
           required={true} onChange={(e) => setPay(e.target.value)} value={pay}
          options={[
            'Open this select menu',
            { label: 'One', value: 'POD'},
            { label: 'Two', value: 'whatsapp'},
            
          ]}
        /> */}
        <CFormCheck type="radio" name="flexRadioDefault" id="flexRadioDefault1"  onChange={(e) => setPay(e.target.value)} label= 'POD(pay on delivery)' value= 'POD' required/>
        <CFormCheck type="radio" name="flexRadioDefault" id="flexRadioDefault2"  onChange={(e) => setPay(e.target.value)} label= 'Pay through WhatsApp' value= 'whatsapp' required/>
        <CCol xs={10}>
          <CFormCheck type="checkbox" id="invalidCheck" label="Agrees to Terms & Conditions" required />
        </CCol>

        <a className='tc' onClick={() => navigate("/tandc")}>Read T&C</a>

        <button >Submit</button>
      </form>
    </div>
  )
}
